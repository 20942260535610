import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import "./style.css";

import { createRoot } from "react-dom/client";

import { StoreContext } from "./store/context";
import App from "./components/App";
import theme from "./theme";
import { ThemeProvider } from "@mui/material";
import { Store } from "./store/indexeddb";
import React from "react";

declare var FLASH_DEV_MODE: boolean;

if (FLASH_DEV_MODE) {
  new EventSource('/esbuild').addEventListener('change', () => location.reload())
}

async function init() {
  const root = createRoot(document.getElementsByTagName("div")[0]!);

  const store = await Store.open();

  root.render(
    <ThemeProvider theme={theme}>
      <StoreContext.Provider value={store}>
        <App />
      </StoreContext.Provider>
    </ThemeProvider>,
  );
}

init();
