
import { useCallback, useContext, useState } from "react";
import useAsyncEffect from "use-async-effect";
import CardRepetition from "./CardRepetition";
import { DeckTitle } from "./DeckOverview";
import { useLocation } from "wouter";
import { StoreContext } from "@/store/context";
import React from "react";
import { Card } from "@/store/types";
import { Box, Stack, Typography } from "@mui/material";
import { Edit } from "@mui/icons-material";


interface Props {
  topic: string;
  deck: string;
}

export default function StudyDeck({ topic, deck }: Props) {
  const store = useContext(StoreContext);
  const [card, setCard] = useState<Card | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [_, setLocation] = useLocation();

  const setNextRep = useCallback(async () => {
    const scheduledRep = await store.getNextCardSM2(topic, deck)
    if (!scheduledRep) {
      setError("No cards to study");
      return;
    }
    setCard(await store.getCard(scheduledRep.cardID));
  }, [store, topic, deck, setCard, setError]);

  useAsyncEffect(async () => {
    await setNextRep();
  }, [setNextRep]);

  const onScore = useCallback(async (card: Card, score: number) => {
    await store.addRep(card, new Date(), score);
    await setNextRep();
  }, [setNextRep, store, card]);

  if ((!card && !error) || !deck || !topic) {
    return <div>Loading...</div>;
  }

  function onQuit() {
    setLocation(`/topics/${topic}/decks/${deck}`);
  }

  return <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', flex: 1 }} mx={2}>
    <Stack direction="row">
      <DeckTitle topic={topic} deck={deck} />
      <Edit onClick={() => setLocation(`/topics/${topic}/decks/${deck}/cards`)} />
    </Stack>
    <Typography color={theme => theme.palette.grey[700]} sx={{ fontVariant: 'small-caps' }}>studying</Typography>
    {error ? <div className="text-error">{error}</div> : null}
    {card ? <CardRepetition deck={deck} card={card} onScore={onScore} onQuit={onQuit} /> : null}
  </Box>;
}
