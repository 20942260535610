import { DBSchema, IDBPDatabase, IDBPTransaction, StoreNames, openDB } from "idb";
import { Card, CardID, Rep } from "./types";

export interface SyncState {
  sequenceNumber: number;
  syncDate: Date;
}

export interface Syncable {
  // Cannot use booleans with indexes in IndexedDB so use 0/1 instead.
  isSynced: 0 | 1;
  isDeleted: 0 | 1;
}

export interface ChangeSet {
  cards: Card[];
  reps: Rep[];
}

interface SM2CardState {
  cardId: CardID;
  topic: string;
  deck: string;
  eFactor: number;
  nextRepDate: Date;
  lastIntervalDays: number;
}

export interface DB extends DBSchema {
  cards: {
    key: CardID;
    value: Card & Syncable,
    indexes: {
      "by-topic": string,
      "by-deck": [topic: string, deck: string],
      "by-full-path": [topic: string, deck: string, id: CardID],
      "by-lastModified": Date,
      "by-synced": number,
    },
  },

  reps: {
    key: [CardID, Date];
    value: Rep & Syncable;
    indexes: {
      "by-deck": [topic: string, deck: string],
      "by-synced": number,
    },
  },

  // The latest state of synchronization from the backend store
  syncs: {
    key: 'sequenceNumber';
    value: SyncState;
  },

  sm2CardState: {
    key: CardID;
    value: SM2CardState;
    indexes: { "by-nextRep": [topic: string, deck: string, nextRepDate: Date, cardId: CardID] };
  }
}

// Use branding since TS doesn't support exact types, which would be necessary to ensure all of the
// stores have been specified in the tx.
export type FullTransaction = IDBPTransaction<DB, StoreNames<DB>[], "readwrite"> & { __brand: 'full' };
export type ReadOnlyTransaction = IDBPTransaction<DB, StoreNames<DB>[], "readonly"> & { __brand: 'readonly' };

export function startWriteTx(db: IDBPDatabase<DB>): FullTransaction {
  const tx = db.transaction(["cards", "reps", "syncs", "sm2CardState"], "readwrite", {durability: "relaxed"});
  return tx as typeof tx & { __brand: 'full' };
}

export function startReadOnlyTx(db: IDBPDatabase<DB>): ReadOnlyTransaction {
  const tx = db.transaction(["cards", "reps", "syncs", "sm2CardState"], "readonly");
  return tx as typeof tx & { __brand: 'readonly' };
}

const defaultDBName = "flashcards";
const dbVersion = 1;

export async function getDB(dbName: string = defaultDBName) {
  return await openDB<DB>(dbName, dbVersion, {
    upgrade(db/*, oldVersion, newVersion, transaction, event*/) {
      const cards = db.createObjectStore("cards", {
        keyPath: 'id',
        autoIncrement: false,
      });
      cards.createIndex("by-topic", "topic");
      cards.createIndex("by-deck", ["topic", "deck"]);
      cards.createIndex("by-full-path", ["topic", "deck", "id"]);
      cards.createIndex("by-lastModified", "lastModified");
      cards.createIndex("by-synced", "isSynced");

      const reps = db.createObjectStore("reps", {
        keyPath: ['cardId', 'timestamp'],
      });
      reps.createIndex("by-deck", ["topic", "deck"]);
      reps.createIndex("by-synced", "isSynced");

      db.createObjectStore('syncs', {
        keyPath: 'sequenceNumber',
      });

      const cardState = db.createObjectStore("sm2CardState", {
        keyPath: 'cardId',
        autoIncrement: false,
      });
      cardState.createIndex("by-nextRep", ["topic", "deck", "nextRepDate", "cardId"]);
    },
  });
}

