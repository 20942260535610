import React, { useCallback, useContext, useMemo, useRef, useState } from "react";
import RenderedCard from "./RenderedCard";
import { DeckTitle } from "./DeckOverview";
import { Box, Button, CircularProgress, ClickAwayListener, Dialog, DialogActions, DialogContent, Divider, List, ListItem, ListItemButton, Stack, TextField } from "@mui/material";
import { StoreContext } from "@/store/context";
import useStore from "@/hooks/useStore";
import { Card } from "@/store/types";
import AutocompleteWithNew from "./AutocompleteWithNew";
import { Check as CheckIcon, Close as CloseIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { generateID } from "@/store/indexeddb";
import { useLocation } from "wouter";


interface Props {
  topic: string;
  deck: string;
}

export default function CardEditing({ topic, deck }: Props) {
  const [_, setLocation] = useLocation();

  const store = useContext(StoreContext);
  const cards = useStore((store) => topic && deck ? store.getCardsForDeck(topic, deck) : Promise.resolve(null), [topic, deck])
  const [newCards, setNewCards] = useState([] as Card[]);
  const [editing, setEditing] = useState<{ [index: string]: boolean }>({});
  const sortedCards = useMemo(() => [...(cards || [])].sort((a, b) => a.createdOn.getTime() - b.createdOn.getTime()), [cards])

  if (!topic || !deck) {
    return <DeckSelection onTopicChange={(topic: string) => {
      if (topic) {
        setLocation(`/topics/${topic}/cards`);
      }
    }} onDeckChange={(deck: string) => {
      if (deck) {
        setLocation(`/topics/${topic}/decks/${deck}/cards`);
      }
    }} topic={topic} deck={deck} />
  }

  return <Stack mx={1}>
    <DeckTitle topic={topic} deck={deck} />
    <List>
      {sortedCards.map(c => <React.Fragment key={c.id}>
        <ListItem divider disablePadding dense disableGutters>
          {editing[c.id] ?
            <EditCard card={c}
              onCancel={() => { setEditing({ ...editing, [c.id]: false }) }}
              onDelete={async () => { await store.deleteCards(c.id); }}
              onSave={async (card) => {
                await store.addOrUpdateCards(card);
                setEditing({ ...editing, [c.id]: false })
              }}
            /> :
            <ListItemButton disableGutters onClick={() => { setEditing({ ...editing, [c.id]: true }); }}>
              <ViewCard card={c} />
            </ListItemButton>
          }
        </ListItem>
      </React.Fragment>)}
      {newCards.map((c, i) => <>
        <ListItem key={i} divider disablePadding dense disableGutters>
          <EditCard card={c}
            onSave={async (card) => {
              await store.addOrUpdateCards(card);
              setNewCards(ncs => [...ncs.slice(0, i), ...ncs.slice(i + 1)]);
            }}
            onDelete={() => {
              setNewCards(ncs => [...ncs.slice(0, i), ...ncs.slice(i + 1)]);
            }}
            onCancel={() => {
              setNewCards(ncs => [...ncs.slice(0, i), ...ncs.slice(i + 1)]);
            }}
          />
        </ListItem>
      </>)}
    </List>
    <span className="button-primary" onClick={() => {
      setNewCards(ncs => [...ncs, { topic, deck, id: generateID(), front: "", back: "", createdOn: new Date(), }])
    }}>+ Add Card</span>
  </Stack>;
}

function ViewCard({ card }: { card: Card }) {
  return <Stack direction="row" spacing={1} alignItems="center">
    <Box flexGrow={1} p={1}>
      <RenderedCard text={card.front} />
    </Box>
    <Box flexGrow={1} backgroundColor="grey.100" p={1}>
      <RenderedCard text={card.back} />
    </Box>
  </Stack>
    ;
}

function EditCard({ card, onCancel, onSave, onDelete }: { card: Card, onCancel: () => void, onSave: (card: Card) => void, onDelete: () => void }) {
  const frontRef = useRef<HTMLInputElement>(null);
  const backRef = useRef<HTMLInputElement>(null);

  const doSave = useCallback(() => {
    if (frontRef.current === null || backRef.current === null) {
      return;
    }
    const front = frontRef.current.value;
    const back = backRef.current.value;
    frontRef.current.value = "";
    backRef.current.value = "";

    onSave({ ...card, front, back });
  }, [card, onSave]);

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const onConfirmDelete = useCallback(() => {
    onDelete();
    setShowConfirmDelete(false);
  }, [setShowConfirmDelete])


  function keypress(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === "Enter" && e.getModifierState("Shift")) {
      e.preventDefault()
      e.stopPropagation();
      doSave();
    } else if (e.key === "Escape") {
      e.preventDefault()
      e.stopPropagation();
      onCancel();
    }
  }

  return <ClickAwayListener onClickAway={onCancel}>
    <Stack spacing={1} my={1} direction="row" alignItems="center">
      <TextField
        onKeyDown={keypress}
        autoFocus
        inputRef={frontRef}
        label="Front"
        multiline
        onFocus={(e) =>
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length
          )}
        defaultValue={card.front} />
      <TextField
        onKeyDown={keypress}
        inputRef={backRef}
        label="Back"
        multiline
        defaultValue={card.back} />
      <CheckIcon onClick={() => doSave()}>Save</CheckIcon>
      <DeleteIcon color="error" onClick={() => { setShowConfirmDelete(true) }}>Delete</DeleteIcon>
      <Dialog open={showConfirmDelete} onClose={() => { setShowConfirmDelete(false); }}>
        <DialogContent>
          Are you sure you want to delete card:

          <div>
            {card.front}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onConfirmDelete}>Delete</Button>
          <Button onClick={() => { setShowConfirmDelete(false); }}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Stack>
  </ClickAwayListener>;
}

function DeckSelection({ onTopicChange, onDeckChange, topic, deck }: { onTopicChange: (topic: string) => unknown, onDeckChange: (deck: string) => unknown, topic: string, deck: string }) {
  const topicOptions = useStore(store => store.getTopics(), []);
  const deckOptions = useStore(store => topic ? store.getDecksForTopic(topic) : Promise.resolve([]), [topic]);

  if (!topicOptions || !deckOptions) {
    return <CircularProgress />;
  }

  return <Stack spacing={1} mx={1}>
    <div>Select topic and deck to edit cards</div>
    <AutocompleteWithNew options={topicOptions} onChange={onTopicChange} label="Topic" value={topic || null} />
    <AutocompleteWithNew options={deckOptions} onChange={onDeckChange} label="Deck" value={deck || null} />
  </Stack>;
}

