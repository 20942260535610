import { AppBar, Box, Button, CircularProgress, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Snackbar, Toolbar, Typography } from "@mui/material";
import { Menu as MenuIcon, Sync as SyncIcon, SyncDisabled as SyncDisabledIcon } from "@mui/icons-material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "wouter";
import { S3Syncer } from "@/store/sync/s3";
import { StoreContext } from "@/store/context";

interface Props {
  syncer: S3Syncer | null;
}

export default function TopBar({ syncer }: Props) {
  const [_, setLocation] = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  return <Box sx={{ flexGrow: 1 }}>
    <AppBar position="static">
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => {
            setShowMenu(b => !b);
          }}
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, cursor: "pointer" }} onClick={() => { setLocation("#/") }}>
          Flash
        </Typography>
        <Syncing syncer={syncer} />
      </Toolbar>
    </AppBar>
    <Drawer
      anchor="left"
      open={showMenu}
      onClose={() => setShowMenu(false)}
    >
      <Box
        sx={{ width: 300, bgcolor: "background.paper" }}
        role="presentation"
        onClick={() => setShowMenu(false)}
      >
        <nav>
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => setLocation("/setup-sync")}>
                <ListItemText primary="Configure Sync" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary="Reset" />
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
      </Box>
    </Drawer>
  </Box>;
}

function Syncing({ syncer }: Props) {
  const store = useContext(StoreContext);
  const [_, setLocation] = useLocation();
  const [isSyncing, setIsSyncing] = useState(false);
  const [needsSync, setNeedsSync] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    if (!syncer) {
      return;
    }

    Promise.all([
      store.hasLocalChanges(),
      syncer.hasRemoteChanges(),
    ]).then(vs => setNeedsSync(vs.reduce((acc, v) => acc || v, false)));

    const sub = store.cardUpdates$.subscribe(_ => {
      setNeedsSync(true);
    })
    return () => sub.unsubscribe();
  }, [store, syncer]);

  const doSync = useCallback(async () => {
    if (syncer) {
      setIsSyncing(true);
      try {
        await syncer.syncFromS3();
        await syncer.syncToS3();
        setMessage("Sync successful");
        setNeedsSync(false);
      } catch (e) {
        setMessage(`Error syncing: ${e}`);
      } finally {
        setIsSyncing(false);
      }
    }
  }, [syncer]);

  return <>
    {isSyncing ?
      <CircularProgress size={24} color="inherit" /> :
      !!syncer ?
        (needsSync ?
          <SyncIcon onClick={doSync}>Sync</SyncIcon> :
          null) :
        <SyncDisabledIcon onClick={() => setLocation(`/setup-sync`)}>Setup Sync</SyncDisabledIcon>}
    <Snackbar
      open={!!message}
      autoHideDuration={3000}
      onClose={() => setMessage("")}
      message={message} />
  </>;
}
