import { PaletteOptions, createTheme } from "@mui/material";
import { common } from '@mui/material/colors';
import createPalette from "@mui/material/styles/createPalette";

const defaultPalette = createPalette({} as PaletteOptions);

const theme = createTheme({
  palette: {
    score0: {
      main: common.black,
    },
    score1: defaultPalette.error,
    score2: defaultPalette.warning,
    score3: defaultPalette.secondary,
    score4: defaultPalette.success,
    score5: defaultPalette.primary,
  },
});
export default theme;
