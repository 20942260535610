// Basically JSON serializable values (except objects)
type SimpleValue = (string | number | boolean | Array<SimpleValue>);
// An object that consists of SimpleValue values that can also be undefined.
type SimpleOptionalObject<T> = {
  [K in keyof T]:
  T[K] extends SimpleValue ? T[K] : never
};

export default class LocalStorageMapper<T extends SimpleOptionalObject<T>> {
  constructor(readonly prefix: string) { }

  public load(defaultObj: T): T {
    const out = { ...defaultObj } as T;
    Object.keys(out).forEach(k => {
      const v = this._loadValue(k);
      if (v !== null) {
        out[k] = v;
      }
    });
    return out;
  }

  private _loadValue(key: string): any {
    const item = localStorage.getItem(`${this.prefix}${key}`);
    if (item === null) {
      return null;
    }

    try {
      return JSON.parse(item);
    } catch (e) {
      // Assume it is a manually inserted raw string and return it as such.
      return item;
    }
  }

  public store(o: Partial<T>) {
    const keys = Object.keys(o);
    keys.forEach(k => {
      const v = JSON.stringify(o[k]);
      localStorage.setItem(`${this.prefix}${k}`, v);
    });
  }
}
