import { Box } from "@mui/material";
import MarkdownIt from "markdown-it";
import React from "react";
import { useMemo } from "react";

const md = new MarkdownIt("commonmark");

export default function RenderedCard(props: {text: string}) {
  const rendered = useMemo(() => {
    return {__html: md.render(props.text ?? "")};
  }, [props.text]);

  return <Box sx={{fontFamily: 'Tinos', fontSize: '2em'}} dangerouslySetInnerHTML={rendered} />;
}

