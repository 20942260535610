import useStore from "@/hooks/useStore";
import { StoreContext } from "@/store/context";
import { DeckStats, Rep } from "@/store/types";
import { Breadcrumbs, Button, CircularProgress, Dialog, DialogActions, DialogContent, Link, Stack, Typography } from "@mui/material";
import React, { useCallback, useContext, useState } from "react";
import { useMemo } from "react";
import { Line, Pie } from "react-chartjs-2";

interface Props {
  topic: string;
  deck: string;
}

export default function DeckOverview({ topic, deck }: Props) {
  const reps = useStore(async store => await store.getRepsForDeck(topic, deck), [topic, deck]);
  const deckStats = useStore(store => store.getDeckStats(topic, deck), [topic, deck]);
  const store = useContext(StoreContext);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  if (!deck || !topic || !reps || !deckStats) {
    return <CircularProgress />;
  }

  const doDelete = useCallback(async () => {
    const ids = await store.getCardIdsForDeck(topic, deck);
    await store.deleteCards(...ids);
  }, [store, topic, deck]);

  return <Stack>
    <DeckTitle topic={topic} deck={deck} />
    <Stack justifyContent="space-between">
      <Button href={`#/topics/${topic}/decks/${deck}/preview`}>Preview/Edit</Button>
      <Button onClick={() => { setShowConfirmDelete(true); }}>Delete</Button>
    </Stack>
    <Dialog open={showConfirmDelete} onClose={() => { setShowConfirmDelete(false); }}>
      <DialogContent>
        Are you sure you want to delete deck <Typography sx={{ fontWeigth: 'bold' }}>{deck}</Typography>?
      </DialogContent>
      <DialogActions>
        <Button onClick={doDelete}>Delete</Button>
        <Button onClick={() => { setShowConfirmDelete(false); }} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
    <Button href={`#/topics/${topic}/decks/${deck}/study`}>Study</Button>
    <div>Studied {deckStats.totalCards - deckStats.scoreCounts.neverSeen} out of {deckStats.totalCards} cards.</div>
    <div>{deckStats.scoreCounts.neverSeen} cards not yet studied</div>
    <div>Total Repetitions: {deckStats.totalReps}</div>
    <div>Mastery level: <span className="font-bold">{Math.floor(deckStats.masteryPercent)}%</span></div>
    <div className="flex flex-row flex-wrap">
      {deckStats ? <MasteryPie stats={deckStats} /> : null}
      {reps ? <RepCountChart reps={reps} /> : null}
    </div>
  </Stack>
}

export function DeckTitle({ topic, deck }: { topic: string, deck: string }) {
  return <Breadcrumbs separator="›">
    <Link href="#/topics">Topics</Link>
    <Link href={`#/topics/${topic}`} fontWeight="bold">{topic}</Link>
    <Typography fontWeight="bold">{deck}</Typography>
  </Breadcrumbs>
}

function MasteryPie({ stats }: { stats: DeckStats }) {
  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Worst score in last 3 reps",
      },
    },
  };

  const masteryPieData = useMemo(() => {
    return {
      labels: ["0", "1", "2", "3", "4", "5", "Never seen"],
      datasets: [{
        data: [
          stats.scoreCounts.zero,
          stats.scoreCounts.one,
          stats.scoreCounts.two,
          stats.scoreCounts.three,
          stats.scoreCounts.four,
          stats.scoreCounts.five,
          stats.scoreCounts.neverSeen,
        ],
        backgroundColor: [
          "rgb(0 0 0 / 1)",
          "rgb(220 38 38 / 1)",
          "rgb(202 138 4 / 1)",
          "rgb(253 224 71 / 1)",
          "rgb(22 163 74 / 1)",
          "rgb(37 99 235 / 1)",
          "rgb(209 213 219 / 1)",
        ],
      }],
    };
  }, [stats]);

  return <div className="max-w-sm w-full relative"><Pie data={masteryPieData} options={options} /></div>;
}

function RepCountChart({ reps }: { reps: Rep[] }) {
  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Repetitions Over Time",
      },
    },
  };

  const data = useMemo(() => {
    reps.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
    const repsByDate = new Map<string, number>();
    reps.filter(r => r.timestamp).forEach(r => {
      const ds = r.timestamp.toDateString();
      if (!repsByDate.has(ds)) {
        repsByDate.set(ds, 0);
      }
      repsByDate.set(ds, repsByDate.get(ds)! + 1);
    }, {});

    return {
      labels: [...repsByDate.keys()],
      datasets: [{
        label: "Reps",
        data: [...repsByDate.values()],
        fill: false,
        tension: 0.1,
      }],
    };
  }, [reps]);

  return <div className="max-w-sm w-full relative"><Line data={data} options={options} /></div>;
}
