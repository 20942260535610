import { FullTransaction, ReadOnlyTransaction } from "../schema";
import { CardID, Rep, ScheduledRep } from "@/store/types";

/*
  This is the SuperMemo2 spaced repetiton algorithm as described at https://www.supermemo.com/en/blog/application-of-a-computer-to-improve-the-results-obtained-in-working-with-the-supermemo-method.
  There are more complex algorithms developed later but this one is simple and probably works a lot
  better than just round-robin repetition.
 
  1. Split the knowledge into smallest possible items.
  2. With all items associate an E-Factor equal to 2.5.
  3. Repeat items using the following intervals:
     I(1):=1
     I(2):=6
     for n>2: I(n):=I(n-1)*EF
     where:
     I(n) - inter-repetition interval after the n-th repetition (in days),
     EF - E-Factor of a given item
     If interval is a fraction, round it up to the nearest integer.
  4. After each repetition assess the quality of repetition response in 0-5 grade scale:
      5 - perfect response
      4 - correct response after a hesitation
      3 - correct response recalled with serious difficulty
      2 - incorrect response; where the correct one seemed easy to recall
      1 - incorrect response; the correct one remembered
      0 - complete blackout.
  5. After each repetition modify the E-Factor of the recently repeated item according to the formula:
      EF':=EF+(0.1-(5-q)*(0.08+(5-q)*0.02))
      where:
      EF' - new value of the E-Factor,
      EF - old value of the E-Factor,
      q - quality of the response in the 0-5 grade scale.
      If EF is less than 1.3 then let EF be 1.3.
  6. If the quality response was lower than 3 then start repetitions for the item from the
     beginning without changing the E-Factor (i.e. use intervals I(1), I(2) etc. as if the item was
     memorized anew).
  7. After each repetition session of a given day repeat again all items that scored below four in
     the quality assessment. Continue the repetitions until all of these items score at least four.
 
  I have made one variation to this algorithm that makes it more practical for sporatic use.
  Instead of repeating the failed cards at the end of all of the cards scheduled for the day, a
  failed card is repeated after 20 other reps.
 */

const maxFailedRepSpacing = 20;

export class SM2 {
  async onRep(rep: Rep, tx: FullTransaction): Promise<void> {
    const store = tx.objectStore("sm2CardState");
    let state = await store.get(rep.cardId);
    if (!state) {
      throw new Error("Card should be initialized first");
    }

    if (rep.score < 3) {
      // Set the next rep date to that of the 20th card in the deck (or last if there are less
      // than 20 cards) so that this poorly scored card will show up again soon.
      let reps = await store.index("by-nextRep").getAll(IDBKeyRange.bound([rep.topic, rep.deck], [rep.topic, rep.deck, []]), maxFailedRepSpacing);
      if (reps.length > 0) {
        state.nextRepDate = reps[reps.length-1]!.nextRepDate;
      }
      state.lastIntervalDays = 0;
    } else {
      const nextIntervalDays = sm2CalculateIntervalDays(state.eFactor, rep.score, state.lastIntervalDays);
      state.nextRepDate = new Date(Date.now() + (nextIntervalDays * 60 * 60 * 24 * 1000));
      state.lastIntervalDays = nextIntervalDays;
      state.eFactor = sm2CalculateNewEfactor(state.eFactor, rep.score);
    }
    console.log(`New state for card ${rep.cardId}`, state);
    store.put(state);
  }

  async initCard(topic: string, deck: string, cardId: CardID, tx: FullTransaction) {
    const store = tx.objectStore("sm2CardState");
    const existing = await store.get(cardId);
    if (!existing) {
      await store.add({
        topic: topic,
        deck: deck,
        cardId: cardId,
        eFactor: 2.5,
        nextRepDate: new Date(),
        lastIntervalDays: 0,
      });
    }
  }

  async getNextCardIdForDeck(topic: string, deck: string, tx: ReadOnlyTransaction): Promise<ScheduledRep | null> {
    const c = await tx.objectStore("sm2CardState").index("by-nextRep")
      .openCursor(IDBKeyRange.lowerBound([topic, deck]));
    if (!c || c.value.topic !== topic || c.value.deck !== deck) {
      return null;
    }
    return {
      cardID: c.value.cardId,
      targetDate: c.value.nextRepDate,
    };
  }

  async deleteCard(cardId: CardID, tx: FullTransaction) {
    const cur = await tx.objectStore("sm2CardState").openCursor(IDBKeyRange.bound([cardId], [cardId, []]));
    if (!cur) {
      return;
    }
    for await (const s of cur) {
      await s.delete();
    }
  }
}

// EF':=EF+(0.1-(5-q)*(0.08+(5-q)*0.02))
// where:
// EF' - new value of the E-Factor,
// EF - old value of the E-Factor,
// q - quality of the response in the 0-5 grade scale.
// If EF is less than 1.3 then let EF be 1.3.
// 6. If the quality response was lower than 3 then start repetitions 
// for the item from the beginning without changing the E-Factor 
// (i.e. use intervals I(1), I(2) etc. as if the item was memorized anew).
function sm2CalculateNewEfactor(oldEFactor: number, score: number): number {
  if (score < 3) {
    return oldEFactor;
  }
  return Math.max(1.3, oldEFactor + (0.1 - (5 - score) * (0.08 + (5 - score) * 0.02)));
}

function sm2CalculateIntervalDays(eFactor: number, score: number, lastIntervalDays: number): number {
  if (score < 3) {
    return 1;
  }

  if (lastIntervalDays === 0) {
    return 1;
  } else if (lastIntervalDays === 1) {
    return 6;
  }

  return Math.ceil(lastIntervalDays * eFactor);
}
