import { useCallback, useState } from "react";
import RenderedCard from "./RenderedCard";
import useGlobalKeyDown from "@/hooks/useGlobalKeyDown";
import { Box, Button, ButtonGroup, CircularProgress, Collapse, Container, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { Card } from "@/store/types";

interface Props {
  deck: string;
  card: Card;
  onScore: (card: Card, score: number) => unknown;
  onQuit: () => void;
}

export default function CardRepetition({ card, onScore, onQuit }: Props) {
  const [showAnswer, setShowAnswer] = useState(false);
  useGlobalKeyDown(e => {
    if (e.key == " ") {
      setShowAnswer(true);
    } else if (e.key == "q") {
      onQuit();
    }
  }, [setShowAnswer]);

  if (!card) {
    return <CircularProgress />;
  }

  return <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "center" }}>
      <RenderedCard text={showAnswer ? card.back : card.front} />
    </Box>
    <Box sx={{ visibility: showAnswer ? 'visible' : 'hidden' }}>
      <ScoreBar onScore={async score => {
        await onScore(card, score);
        setShowAnswer(false);
      }} setShowAnswer={setShowAnswer} />
    </Box>
    {showAnswer ?
      <Button onClick={() => { setShowAnswer(false) }}>Show Question</Button> :
      <Button onClick={() => { setShowAnswer(true) }}>Show Answer</Button>
    }
  </Box>;
}

function ScoreBar({ onScore, setShowAnswer }: { onScore: (score: number) => unknown, setShowAnswer: (v: boolean) => void }) {
  const [showHelp, setShowHelp] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSelectScore = useCallback(async (score: number) => {
    console.log("onSelectStore");
    setLoading(true);
    await onScore(score);
    setLoading(false);
    setShowAnswer(false);
  }, [setLoading, onScore, setShowAnswer]);

  useGlobalKeyDown(e => {
    if (e.key >= "0" && e.key <= "5") {
      onSelectScore(Number(e.key));
    }
  }, [onSelectScore]);

  return <>
    {loading ? <CircularProgress /> : <Stack direction="row" justifyContent="center" alignItems="center" useFlexGap spacing={1} sx={{ flexWrap: 'wrap' }}>
      <Button variant="outlined" onClick={() => onSelectScore(0)} color="score0">0</Button>
      <Button variant="outlined" onClick={() => onSelectScore(1)} color="score1">1</Button>
      <Button variant="outlined" onClick={() => onSelectScore(2)} color="score2">2</Button>
      <Button variant="outlined" onClick={() => onSelectScore(3)} color="score3">3</Button>
      <Button variant="outlined" onClick={() => onSelectScore(4)} color="score4">4</Button>
      <Button variant="outlined" onClick={() => onSelectScore(5)} color="score5">5</Button>
      <Button variant="text" onClick={() => { setShowHelp(v => !v) }}>Help</Button>
    </Stack>}
    <Collapse in={showHelp} unmountOnExit>
      <Stack>
        <Typography color="score5">5 - Perfect response</Typography>
        <Typography color="score4">4 - Correct response after a hesitation</Typography>
        <Typography color="score3">3 - Correct response recalled with serious difficulty</Typography>
        <Typography color="score2">2 - Incorrect response; the correct one seemed easy to recall</Typography>
        <Typography color="score1">1 - Incorrect response; the correct one remembered</Typography>
        <Typography color="score0">0 - Complete blackout</Typography>
      </Stack>
    </Collapse>
  </>
}
