import { Router, Redirect, Route, Switch } from "wouter";
import { useHashLocation } from "wouter/use-hash-location";
import DeckOverview from "./DeckOverview";
import CardEditing from "./CardEditing";
import StudyDeck from "./StudyDeck";
import TopicList from "./TopicList";
import TopicOverview from "./TopicOverview";
import { Box, Container, CssBaseline } from "@mui/material";
import React, { useCallback, useContext, useState } from "react";
import { StoreContext } from "@/store/context";
import { S3Syncer } from "@/store/sync/s3";
import Login from "./Login";
import TopBar from "./TopBar";
import { isConfigured, syncerFromConfig } from "@/store/sync/config";

export default function App() {
  const store = useContext(StoreContext);

  const [syncer, setSyncer] = useState<S3Syncer | null>(() => {
    if (isConfigured()) {
      return syncerFromConfig(store);
    }
    return null;
  });

  const onSyncerSetup = useCallback((s: S3Syncer) => {
    setSyncer(s);
  }, [setSyncer]);

  return <>
    <CssBaseline />
    <Container disableGutters sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Router hook={useHashLocation}>
        <Box>
          <TopBar syncer={syncer} />
        </Box>
        <Box sx={{ flexGrow: 1, display: 'flex' }} position="relative" py="1em">
          <Switch>
            <Route path="/topics/:topic/decks/:deck/study">
              {({ topic, deck }) => <StudyDeck topic={topic} deck={deck} />}
            </Route>
            <Route path="/cards">
              {() => <CardEditing topic="" deck="" />}
            </Route>
            <Route path="/topics/:topic/cards">
              {({ topic }) => <CardEditing topic={topic} deck="" />}
            </Route>
            <Route path="/topics/:topic/decks/:deck/cards">
              {({ topic, deck }) => <CardEditing topic={topic} deck={deck} />}
            </Route>
            <Route path="/topics/:topic/decks/:deck">
              {({ topic, deck }) => <DeckOverview topic={topic} deck={deck} />}
            </Route>
            <Route path="/topics/:topic">
              {({ topic }) => <TopicOverview topic={topic} />}
            </Route>
            <Route path="/topics">
              <TopicList />
            </Route>
            <Route path="/setup-sync">
              <Login onSetup={onSyncerSetup} />
            </Route>
            <Route path="/">
              <Redirect to="/topics" />
            </Route>
          </Switch>
        </Box>
      </Router>
    </Container>
  </>;
}

