import { useCallback, useContext, useState, useRef } from "react";
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Link, List, ListItem, ListItemText, Stack } from "@mui/material";
import { useLocation } from "wouter";
import useStore from "@/hooks/useStore";
import React from "react";
import { StoreContext } from "@/store/context";
import { importCards } from "@/store/import";


export default function TopicListPage() {
  const topics = useStore(async store => {
    const ts = await store.getTopics();
    return ts?.sort();
  }, []);
  if (topics === null) {
    return <CircularProgress />;
  }

  return <Stack spacing={1} useFlexGap>
    <Stack direction="row" spacing={1} mx={2}>
      <TopicImportButton />
      <Button href="#/cards" variant="outlined">Add</Button>
    </Stack>
    <TopicList topics={topics} />
  </Stack>;
}

function TopicList({ topics }: { topics: string[] }) {
  if (topics.length === 0) {
    return <Box>No topics yet</Box>;
  }

  return <List dense>
    {topics.map(t => <TopicItem key={t} topic={t} />)}
  </List>
}

function TopicItem({ topic }: { topic: string }) {
  const [_, setLocation] = useLocation();
  const decks = useStore(store => store.getDecksForTopic(topic), [topic]);

  return <ListItem onClick={() => { setLocation(`/topics/${topic}`) }} sx={{ cursor: "pointer" }} >
    <ListItemText primary={topic} secondary={`${decks?.length ?? 0} decks`}></ListItemText>
  </ListItem>;
}

function TopicImportButton() {
  const store = useContext(StoreContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const [results, setResults] = useState<Map<string, [valid: number, errs: Error[]]>>(new Map());
  const [loading, setLoading] = useState(false);

  const onUpload = useCallback(async (files: FileList | null) => {
    if (!files) return;

    setLoading(true);

    const newResults: typeof results = new Map();
    const toImport: File[] = [];
    for (let i = 0; i < files.length; i++) {
      const f = files.item(i);
      if (!f) continue;
      toImport.push(f);
    }

    await Promise.all(toImport
      .map(async file => {
        const [valid, errs] = await importCards(file, store);
        newResults.set(file.name, [valid, errs]);
      }));

    setLoading(false);
    setResults(newResults);
  }, [store, setResults, store]);

  return <Box>
    {loading ?
      <CircularProgress /> :
      <Button onClick={() => { inputRef.current!.click() }} variant="outlined">
        Import
        <input type="file" ref={inputRef} onChange={async (e) => {
          await onUpload(e.currentTarget.files);
          e.target.value = "";
        }} multiple hidden />
      </Button>}
    <Dialog
      open={results.size > 0}
      onClose={() => { setResults(new Map()); }}
    >
      <DialogTitle>Topic Import</DialogTitle>
      <DialogContent>
        {[...results.entries()].map(([filename, res]) => {
          if (res[1].length > 0) {
            return <DialogContentText key={filename} color="error">Failed to import {filename}: {res[1].map(e => e.message)}</DialogContentText>
          } else {
            return <DialogContentText key={filename} color="success">Imported {res[0]} cards from {filename}</DialogContentText>;
          }
        })}
      </DialogContent>
    </Dialog>
  </Box>
}
