import { v4 as uuidv4 } from 'uuid';
import { DeleteObjectCommand, ListObjectsV2Command, PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import sleep from '@/util/sleep';

const lockPrefix = "sync-locks/";

function parseLockKey(key: string): [lockID: string, expiry: Date, err: null] | [lockID: unknown, expiry: unknown, error: Error] {
  if (!key.startsWith(lockPrefix)) {
    return [null, null, new Error("malformed key: " + key)];
  }
  const [id, expiryStr] = key.replace(lockPrefix, "").split(".");
  if (id === undefined || expiryStr === undefined) {
    return [null, null, new Error("malformed object key name: " + key)];
  }
  return [id, new Date(Number.parseInt(expiryStr)), null];
}


export default class S3Mutex {
  private currentLockKey: string | null = null;

  constructor(private s3: S3Client, private bucket: string) { }

  async lock(expiryMillis: number): Promise<unknown> {
    while (true) {
      const lockKey = await this.tryLock(expiryMillis);
      if (lockKey === null) {
        console.log("Unable to obtain sync lock, waiting");
        await sleep(1000);
        continue;
      }
      this.currentLockKey = lockKey;
      return;
    }
  }

  private async tryLock(expiryMillis: number): Promise<string | null> {
    const selfId = uuidv4();
    const key = `${lockPrefix}${selfId}.${Date.now() + expiryMillis}`;
    await this.s3.send(new PutObjectCommand({
      Bucket: this.bucket,
      Key: key,
      Body: Uint8Array.of(),
    }));

    console.log("checking for conflicting clients", selfId);
    const resp = await this.s3.send(new ListObjectsV2Command({
      Bucket: this.bucket,
      Prefix: lockPrefix,
    }));
    for (const o of resp.Contents!) {
      const [id, expiry, err] = parseLockKey(o.Key!);
      console.log("existing claims", id, expiry, err);
      if (err) {
        console.error(err);
        continue;
      }
      if (id !== selfId && expiry.getTime() > Date.now()) {
        await this.s3.send(new DeleteObjectCommand({
          Bucket: this.bucket,
          Key: key,
        }));
        return null;
      }
    }
    return key;
  }

  async unlock(): Promise<unknown> {
    if (!this.currentLockKey) {
      throw new Error("lock is not currently held");
    }
    await this.s3.send(new DeleteObjectCommand({
      Bucket: this.bucket,
      Key: this.currentLockKey,
    }));
    this.currentLockKey = null;
    return;
  }
}
