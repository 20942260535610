import { StoreContext } from "@/store/context";
import { Store } from "@/store/indexeddb";
import { DependencyList, useContext, useEffect, useState } from "react";
import { debounceTime, tap } from "rxjs";

export default function useStore<T extends any>(f: (store: Store) => Promise<T>, deps: DependencyList) {
  const [val, setVal] = useState<T | null>(null);

  const store = useContext(StoreContext);

  useEffect(() => {
    console.log("use effect");
    f(store).then(setVal);

    const sub = store.cardUpdates$.pipe(
      debounceTime(500),
      tap(async _ => {
        console.log("card update");
        setVal(await f(store));
      })).subscribe();

    return () => sub.unsubscribe();
  }, deps.concat([store, setVal]));
  return val;
}
