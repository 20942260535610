import { useEffect } from "react";


export default function useGlobalKeyDown(onKeyDown: (e: KeyboardEvent) => void, deps: React.DependencyList) {
  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, deps);
}
