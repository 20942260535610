import LocalStorageMapper from "localstorage-mapper";
import { S3Syncer } from "../sync/s3";
import { S3Client, S3ClientConfig } from "@aws-sdk/client-s3";
import { Store } from "../indexeddb";

export default interface Config {
  s3Endpoint: string;
  s3Region: string;
  s3Bucket: string;
  s3AccessKeyID: string;
  s3SecretAccessKey: string;
}

const configLoader = new LocalStorageMapper<Config>("flash.");

export function loadConfig(): Config {
  return configLoader.load({
    s3Endpoint: "",
    s3Region: "",
    s3AccessKeyID: "",
    s3SecretAccessKey: "",
    s3Bucket: "",
  } as const);
}

export function saveConfig(conf: Config) {
  configLoader.store(conf);
}

export function isConfigured(): boolean {
  const conf = loadConfig();
  return !!conf.s3AccessKeyID && !!conf.s3SecretAccessKey && !!conf.s3Bucket;
}

export function syncerFromConfig(store: Store): S3Syncer {
  if (!isConfigured()) {
    throw new Error("cannot create syncer without full config");
  }

  const conf = loadConfig();

  const s3ClientConfig: S3ClientConfig = {
    forcePathStyle: true,
    credentials: {
      secretAccessKey: conf.s3SecretAccessKey,
      accessKeyId: conf.s3AccessKeyID,
    },
  };
  if (conf.s3Endpoint) {
    s3ClientConfig.endpoint = conf.s3Endpoint;
  }
  if (conf.s3Region) {
    s3ClientConfig.region = conf.s3Region;
  }

  const s3 = new S3Client(s3ClientConfig);

  return new S3Syncer({
    bucket: conf.s3Bucket,
    s3,
    store,
  });
}
