import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import React, { useMemo } from "react";

interface Props {
  value: string | null;
  options: string[];
  onChange(val: string): unknown;
  label: string;
}

export default function AutocompleteWithNew(props: Props) {
  const filter = useMemo(() => {
    return createFilterOptions<string>({
      ignoreCase: true,
      ignoreAccents: true,
      trim: true,
    });
  }, []);

  const options = useMemo(() => {
    if (props.value && props.options.indexOf(props.value) < 0) {
      return [...props.options, props.value];
    }
    return props.options;
  }, [props.value, props.options]);

  return <Autocomplete
    value={props.value}
    onChange={(_, newValue) => {
      props.onChange(newValue ?? "");
    }}
    filterOptions={(options, params) => {
      const filtered = filter(options, params);

      const { inputValue } = params;

      // Suggest the creation of a new value
      const isExisting = options.some((option) => inputValue === option);
      if (inputValue !== '' && !isExisting) {
        filtered.push(inputValue);
      }

      return filtered;
    }}
    selectOnFocus
    clearOnBlur
    handleHomeEndKeys
    options={options}
    renderOption={(listProps, option) =>
      <li {...listProps}>{props.options.indexOf(option) >= 0 ? option : `Add "${option}"`}</li>}
    sx={{ width: 300 }}
    renderInput={(params) => {
      return (
        <TextField {...params} label={props.label} />
      )
    }}
  />
}
