import { useCallback, useContext, useMemo } from "react";

import presentDownload from "./ui/presentDownload";
import { useLocation } from "wouter";
import { Box, Breadcrumbs, Button, CircularProgress, Link, List, ListItem, ListItemButton, ListItemText, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { exportCards } from "@/store/import";
import { StoreContext } from "@/store/context";
import useStore from "@/hooks/useStore";
import { DeckStats } from "@/store/types";
import { Edit as EditIcon } from "@mui/icons-material";


export default function TopicOverview({ topic }: { topic: string }) {
  const store = useContext(StoreContext);
  const deckStats = useStore(store => store.getDeckStatsForTopic(topic), [topic])

  const doExport = useCallback(async () => {
    if (!topic) {
      return;
    }
    const decks = await store.getDecksForTopic(topic);
    const cards = await Promise.all(decks.map(d => store.getCardsForDeck(topic, d)));
    const exportBlob = await exportCards(cards.flat());
    presentDownload(`${topic}.topic.yaml`, exportBlob);
  }, [topic]);

  if (!topic || !deckStats) {
    return <CircularProgress />;
  }

  return <Stack mx={2}>
    <Breadcrumbs separator="›">
      <Link href="#/topics">Topics</Link>
      <Typography fontWeight="bold">{topic}</Typography>
    </Breadcrumbs>
    <Typography color={theme => theme.palette.grey[700]} sx={{ fontVariant: 'small-caps' }}>decks</Typography>
    <Stack direction="row" spacing={1}>
      <Button variant="outlined" href={`#/topics/${topic}/cards`}>
        Add Deck
      </Button>
      <Button variant="outlined" onClick={doExport}>Export</Button>
    </Stack>
    <DeckList deckStats={deckStats} topic={topic} />
  </Stack>;
}

function DeckList({ topic, deckStats }: { deckStats: DeckStats[], topic: string }) {
  if (deckStats.length === 0) {
    return <div>No decks yet</div>;
  }


  return <List dense>
    {deckStats.map(ds => <DeckItem key={topic + ds.deck} topic={topic} stats={ds} />)}
  </List >
}

function DeckItem({ topic, stats }: { topic: string, stats: DeckStats }) {
  const [_, setLocation] = useLocation();
  return <ListItem
    disableGutters
    disablePadding
    dense
    key={stats.deck}
    secondaryAction={
      <EditIcon onClick={() => setLocation(`/topics/${topic}/decks/${stats.deck}/cards`)} />
    }
  >
    <ListItemButton disableGutters dense>
      <ListItemText onClick={() => setLocation(`/topics/${topic}/decks/${stats.deck}/study`)} secondary={
        <><span>{stats.totalCards} Cards</span> | <DeckProgressGraph scoreCounts={stats.scoreCounts ?? null} /></>
      }>
        {stats.deck}
      </ListItemText>
    </ListItemButton>
  </ListItem>;
}

function DeckProgressGraph({ scoreCounts }: { scoreCounts: DeckStats['scoreCounts'] }) {
  const theme = useTheme();
  const total = useMemo(() => {
    return scoreCounts.neverSeen + scoreCounts.zero + scoreCounts.one + scoreCounts.two + scoreCounts.three + scoreCounts.four + scoreCounts.five;
  }, [scoreCounts]);

  if (!scoreCounts) {
    return null;
  }

  if (scoreCounts.neverSeen === total) {
    return <span>Never studied</span>;
  }

  return <Stack direction="row" height={10} width="80%" display="inline-block" component="span">
    <ScoreSegment count={scoreCounts.neverSeen} total={total} color={theme.palette.grey[800]} />
    <ScoreSegment count={scoreCounts.zero} total={total} color={theme.palette.score0.main} />
    <ScoreSegment count={scoreCounts.one} total={total} color={theme.palette.score1.main} />
    <ScoreSegment count={scoreCounts.two} total={total} color={theme.palette.score2.main} />
    <ScoreSegment count={scoreCounts.three} total={total} color={theme.palette.score3.main} />
    <ScoreSegment count={scoreCounts.four} total={total} color={theme.palette.score4.main} />
    <ScoreSegment count={scoreCounts.five} total={total} color={theme.palette.score5.main} />
  </Stack >;
}

function ScoreSegment({ count, total, color }: { count: number, total: number, color: string }) {
  return <Box height="100%" width={`${count / total * 100}%`} bgcolor={color} component="span" display="inline-block" />
}
