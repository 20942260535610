import { v4 as uuidv4 } from 'uuid';
import { IDBPDatabase } from "idb";
import { Card, CardID, DeckStats, Rep, ScheduledRep } from "./types";
import { SM2 } from "./repstrategy/sm2";
import { ChangeSet, DB, ReadOnlyTransaction, Syncable, getDB, startReadOnlyTx, startWriteTx } from './schema';
import { Observable, Subject } from 'rxjs';


export class Store {
  #cardUpdates$ = new Subject<CardID>();

  constructor(private db: IDBPDatabase<DB>, readonly sm2: SM2) { }

  static async open(dbName?: string): Promise<Store> {
    const db = await getDB(dbName);
    const sm2 = new SM2();
    return new Store(db, sm2);
  }

  async getTopics(): Promise<string[]> {
    const c = await this.db.transaction('cards').store.index('by-topic').openKeyCursor(undefined, "nextunique");
    const out = [] as string[];
    if (c) {
      for await (const t of c) {
        out.push(t.key);
      }
    }
    return out;
  }

  async getDecksForTopic(topic: string): Promise<string[]> {
    const tx = startReadOnlyTx(this.db);
    return this.getDecksForTopicTx(topic, tx);
  }

  private async getDecksForTopicTx(topic: string, tx: ReadOnlyTransaction): Promise<string[]> {
    const c = await tx.objectStore("cards").index('by-deck').openKeyCursor(IDBKeyRange.bound([topic], [topic, []]), "nextunique");
    const out = [] as string[];
    if (c) {
      for await (const t of c) {
        out.push(t.key[1]);
      }
    }
    return out;
  }

  async getCardsForDeck(topic: string, deck: string): Promise<Card[]> {
    const c = await this.db.transaction('cards', 'readonly').store.index('by-deck').openCursor(IDBKeyRange.only([topic, deck]));
    const out = [] as Card[];
    if (c) {
      for await (const t of c) {
        if (!t.value.isDeleted) {
          out.push(t.value);
        }
      }
    }
    return out;
  }

  async getCardIdsForDeck(topic: string, deck: string): Promise<CardID[]> {
    const tx = startReadOnlyTx(this.db);
    return this.getCardIdsForDeckTx(topic, deck, tx);
  }

  private async getCardIdsForDeckTx(topic: string, deck: string, tx: ReadOnlyTransaction): Promise<CardID[]> {
    const c = await tx.objectStore("cards").index('by-full-path').openCursor(IDBKeyRange.bound([topic, deck], [topic, deck, []]));
    const out = [] as CardID[];
    if (c) {
      for await (const t of c) {
        if (!t.value.isDeleted) {
          out.push(t.value.id);
        }
      }
    }
    return out;
  }

  async addOrUpdateCards(...card: (Card & { lastModified?: Date })[]) {
    const tx = startWriteTx(this.db);
    for (const c of card) {
      if (!c.id) {
        throw new Error("card id cannot be blank");
      }
      await tx.objectStore("cards").put({
        ...c,
        isSynced: 0,
        isDeleted: 0,
        lastModified: new Date(),
      });
      await this.sm2.initCard(c.topic, c.deck, c.id, tx);
      this.#cardUpdates$.next(c.id)
    }
    await tx.done;
  }

  async deleteCards(...cardIDs: CardID[]) {
    const tx = startWriteTx(this.db);
    for (const id of cardIDs) {
      const card = await tx.objectStore("cards").get(id);
      if (!card) {
        continue
      }

      await tx.objectStore("cards").put({
        ...card,
        isSynced: 0,
        isDeleted: 1,
        lastModified: new Date(),
      });
      await this.sm2.deleteCard(card.id, tx);
      this.#cardUpdates$.next(card.id)
    }
    await tx.done;

  }

  async moveDeck(topic: string, deck: string, newTopic: string, newDeck: string) {
    const tx = startWriteTx(this.db);
    const cur = await tx.objectStore("cards").index('by-deck').openCursor(IDBKeyRange.only([topic, deck]));
    if (!cur) {
      return;
    }
    for await (const c of cur) {
      await c.update({ ...c.value, isSynced: 0, isDeleted: 1 });
      await this.sm2.deleteCard(c.value.id, tx);

      const newID = generateID();
      const newCard = {
        ...c.value,
        id: newID,
        topic: newTopic,
        deck: newDeck,
        isSynced: 0,
      } as const;
      await tx.objectStore("cards").put(newCard);
      await this.sm2.initCard(newCard.topic, newCard.deck, newCard.id, tx);
      this.#cardUpdates$.next(newCard.id);

      const repCur = await tx.objectStore("reps").openCursor(IDBKeyRange.bound([c.value.id], [c.value.id, []]))
      if (!repCur) {
        continue;
      }
      for await (const r of repCur) {
        await r.update({ ...r.value, isSynced: 0, isDeleted: 1 });
        const newRep = {
          ...r.value,
          cardId: newID,
          topic: newTopic,
          deck: newDeck,
          isSynced: 0,
        } as const;
        await tx.objectStore("reps").put(newRep);
        await this.sm2.onRep(newRep, tx);
      }
    }
    await tx.done;
  }

  async getCard(cardID: CardID): Promise<Card> {
    const card = await this.db.transaction("cards", "readonly").store.get(cardID);
    if (!card) {
      throw new Error("card not found");
    }
    return card;
  }

  async getNextCardSM2(topic: string, deck: string): Promise<ScheduledRep | null> {
    const tx = startReadOnlyTx(this.db);
    return await this.sm2.getNextCardIdForDeck(topic, deck, tx);
  }

  async getRepsForDeck(topic: string, deck: string): Promise<Rep[]> {
    const c = await this.db.transaction('reps').store.index("by-deck").openCursor(IDBKeyRange.only([topic, deck]));
    const out = [] as Rep[];
    if (c) {
      for await (const r of c) {
        out.push(r.value);
      }
    }
    return out.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
  }

  async repCountForDeck(topic: string, deck: string): Promise<number> {
    return await this.db.transaction('reps').store.index("by-deck").count(IDBKeyRange.only([topic, deck]));
  }

  // Returns reps starting from the most recent, up to limit count (or all reps if not specified).
  async getReps(cardId: string, limit?: number): Promise<Rep[]> {
    const c = await this.db.transaction('reps').store.openCursor(IDBKeyRange.bound([cardId], [cardId, []]), "prev");
    const out = [] as Rep[];
    if (c) {
      for await (const r of c) {
        out.push(r.value);
        if (limit && out.length >= limit) {
          break;
        }
      }
    }
    return out;
  }

  async addRep(card: Pick<Card, 'topic' | 'deck' | 'id'>, timestamp: Date, score: number) {
    if (score > 5 || score < 0 || !Number.isInteger(score)) {
      throw new Error("score is invalid: " + score);
    }
    const tx = startWriteTx(this.db);
    const rep: Rep = {
      topic: card.topic,
      deck: card.deck,
      cardId: card.id,
      timestamp,
      score,
    } as const;
    await tx.objectStore("reps").put({
      ...rep,
      isSynced: 0,
      isDeleted: 0,
    });

    await this.sm2.onRep(rep, tx);
    await tx.done;

    this.#cardUpdates$.next(card.id);
  }

  // Returns a set of changes that have not been previously synced to the remote. Once the returned
  // changes have been synced, the markChangeSetAsSynced method should be called. No other changes
  // should be made to the database until markChangeSetAsSynced is called.
  async extractUnsyncedChanges(): Promise<ChangeSet> {
    const tx = this.db.transaction(['cards', 'reps'], 'readonly');

    const out: ChangeSet = {
      cards: [],
      reps: [],
    };
    const cardCur = await tx.objectStore("cards").index("by-synced").openCursor(IDBKeyRange.only(0));
    if (cardCur) {
      for await (const card of cardCur) {
        let c: Card & Partial<Syncable> = card.value;
        delete c.isSynced;
        out.cards.push(c);
      }
    }

    const repCur = await tx.objectStore("reps").index("by-synced").openCursor(IDBKeyRange.only(0));
    if (repCur) {
      for await (const rep of repCur) {
        let r: Rep & Partial<Syncable> = rep.value;
        delete r.isSynced;
        out.reps.push(r);
      }
    }

    return out;
  }

  async hasLocalChanges(): Promise<boolean> {
    const tx = this.db.transaction(['cards', 'reps'], 'readonly');
    return (await Promise.all([
      tx.objectStore("cards").index("by-synced").count(0),
      tx.objectStore("reps").index("by-synced").count(0)
    ])).some(n => n > 0);
  }

  async getLatestSync(): Promise<number | null> {
    const c = await this.db.transaction('syncs').store.openCursor(undefined, "prev");
    if (c) {
      return c.value.sequenceNumber;
    }
    return null;
  }

  async processChangeSetFromRemote(changes: ChangeSet, seq: number) {
    const tx = startWriteTx(this.db);
    for (const c of changes.cards ?? []) {
      await tx.objectStore("cards").put({ ...c, isSynced: 1, isDeleted: 0, });
      await this.sm2.initCard(c.topic, c.deck, c.id, tx);
      this.#cardUpdates$.next(c.id);
    }

    const repStore = tx.objectStore("reps");
    for (const r of changes.reps ?? []) {
      // If the rep isn't already present, call onRep for the space rep strategy
      if ((await repStore.getKey([r.cardId, r.timestamp])) === undefined) {
        this.sm2.onRep(r, tx);
      }
      await repStore.put({ ...r, isSynced: 1, isDeleted: 0, });
    }
    await tx.objectStore("syncs").put({ sequenceNumber: seq, syncDate: new Date() });
    await tx.done;
  }

  async getDeckStats(topic: string, deck: string): Promise<DeckStats> {
    const tx = startReadOnlyTx(this.db);
    return this.getDeckStatsTx(topic, deck, tx);
  }

  async getDeckStatsForTopic(topic: string): Promise<DeckStats[]> {
    const tx = startReadOnlyTx(this.db);
    const decks = await this.getDecksForTopicTx(topic, tx);
    return Promise.all(decks.map(d => this.getDeckStatsTx(topic, d, tx)));
  }

  private async getDeckStatsTx(topic: string, deck: string, tx: ReadOnlyTransaction): Promise<DeckStats> {
    const cardIDs = await this.getCardIdsForDeckTx(topic, deck, tx);
    const lastThreeRepSets = await Promise.all(cardIDs.map(id => this.getReps(id, 3)));
    const scoreCounts = {
      zero: 0,
      one: 0,
      two: 0,
      three: 0,
      four: 0,
      five: 0,
      neverSeen: 0,
    };
    let masteryTotal = 0;
    for (const lastThreeReps of lastThreeRepSets) {
      if (!lastThreeReps.length) {
        scoreCounts.neverSeen++;
        continue;
      }
      const bestRecentScore = Math.max(...lastThreeReps.map(r => r.score));
      scoreCounts[numberToName(bestRecentScore)]++;
      masteryTotal += bestRecentScore / 5;
    }

    return {
      topic,
      deck,
      totalCards: cardIDs.length,
      totalReps: await this.repCountForDeck(topic, deck),
      masteryPercent: (masteryTotal / cardIDs.length) * 100,
      scoreCounts,
    };
  }

  get cardUpdates$(): Observable<CardID> {
    return this.#cardUpdates$;
  }
}

export function generateID(): CardID {
  return uuidv4();
}

function numberToName(n: number): "zero" | "one" | "two" | "three" | "four" | "five" {
  switch (n) {
    case 0:
      return "zero";
    case 1:
      return "one";
    case 2:
      return "two";
    case 3:
      return "three";
    case 4:
      return "four";
    case 5:
      return "five";
    default:
      throw new Error("Unsupported number: " + n);
  }
}
