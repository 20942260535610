import { Store } from "@/store/indexeddb";
import { GetObjectCommand, ListObjectsV2Command, NoSuchKey, PutObjectCommand, S3Client, S3ServiceException, paginateListObjectsV2 } from "@aws-sdk/client-s3";
import S3Mutex from "./mutex";
import { ChangeSet } from "../schema";

interface Config {
  s3: S3Client;
  bucket: string;
  store: Store;
}

/*
 * S3 bucket layout:
 *
 *  - All updated cards and reps: updates/<sequence #>.json
 */
export class S3Syncer {
  private s3: S3Client;
  private bucket: string;
  private store: Store;
  private mutex: S3Mutex;

  constructor({ s3, bucket, store }: Config) {
    this.s3 = s3;
    this.mutex = new S3Mutex(this.s3, bucket);
    this.bucket = bucket;
    this.store = store;
  }

  async isAuthenticated(): Promise<boolean> {
    try {
      await this.s3.send(new ListObjectsV2Command({
        Bucket: this.bucket,
        MaxKeys: 1,
      }));
    } catch (e) {
      if (e instanceof S3ServiceException) {
        return false;
      }
      throw e;
    }
    console.log("syncer is authenticated");
    return true;
  }

  async syncToS3() {
    await this.mutex.lock(1000 * 30);
    try {
      const latestLocal = await this.store.getLatestSync() ?? -1;
      const latestS3 = await this.checkLatestRemoteUpdate() ?? -1;

      if (latestLocal < latestS3) {
        await this.syncFromS3();
      }
      if (latestS3 < latestLocal) {
        throw new Error(`Updates got deleted from s3, cannot sync: ${latestLocal} is not present on s3`);
      }
      const changeSet = await this.store.extractUnsyncedChanges();
      if (changeSet.cards.length === 0 && changeSet.reps.length === 0) {
        console.log("Nothing to sync");
        return;
      }
      const newSeq = latestS3 + 1;
      await this.s3.send(new PutObjectCommand({
        Bucket: this.bucket,
        Key: `updates/${newSeq}.json`,
        Body: JSON.stringify(changeSet),
      }));
      await this.store.processChangeSetFromRemote(changeSet, newSeq);
    } finally {
      await this.mutex.unlock();
    }
  }

  async syncFromS3() {
    const latestLocal = await this.store.getLatestSync() ?? -1;
    const latestS3 = await this.checkLatestRemoteUpdate() ?? -1;

    if (latestS3 < latestLocal) {
      // Updates apparently got deleted from the remote side.
      console.error("Local changes are newer than remote changes");
      return;
    }

    if (latestLocal === latestS3) {
      console.log("No new changes on s3");
      return;
    }

    for (let seq = latestLocal + 1; seq <= latestS3; seq++) {
      await this.syncUpdateFromS3(seq);
    }
  }

  private async checkLatestRemoteUpdate(): Promise<number | null> {
    const paginator = paginateListObjectsV2({ client: this.s3 }, {
      Bucket: this.bucket,
      Prefix: "updates/",
    });

    let latest: number | null = null;
    for await (const p of paginator) {
      for (const o of p.Contents ?? []) {
        const seq = parseUpdateKey(o.Key!);
        if (latest === null || seq > latest) {
          latest = seq;
        }
      }
    }
    return latest;
  }

  public async hasRemoteChanges(): Promise<boolean> {
    const latestLocal = await this.store.getLatestSync() ?? -1;
    const latestS3 = await this.checkLatestRemoteUpdate() ?? -1;

    return latestLocal < latestS3;
  }

  private async syncUpdateFromS3(seq: number) {
    try {
      const obj = await this.s3.send(new GetObjectCommand({
        Bucket: this.bucket,
        Key: `updates/${seq}.json`,
      }));
      const update = JSON.parse(await obj.Body!.transformToString(), (k, v) => {
        if (k === "createdOn" || k === "lastModified") {
          return new Date(Date.parse(v));
        }
        return v;
      }) as ChangeSet;
      await this.store.processChangeSetFromRemote(update, seq);
    } catch (e) {
      if (e instanceof NoSuchKey) {
        console.log(`update ${seq} does not exist on the remote, skipping`);
        return;
      }
      throw e;
    }
  }
}

function parseUpdateKey(key: string): number {
  const match = /updates\/(\d+).json/.exec(key);
  if (!match) {
    throw new Error("Malformed update key: " + key);
  }
  return parseInt(match[1]!);
}


