import { useForm, SubmitHandler } from "react-hook-form";
import { useCallback, useContext, useState } from "react";
import { Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { S3Syncer } from "@/store/sync/s3";
import Config, { loadConfig, saveConfig, syncerFromConfig } from "@/store/sync/config";
import { StoreContext } from "@/store/context";

interface Props {
  onSetup: (syncer: S3Syncer) => void;
}

type Inputs = Config;

export default function Login({ onSetup: onLoggedIn }: Props) {
  const [authError, setAuthError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [testing, setTesting] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>({
    defaultValues: loadConfig(),
  });
  const store = useContext(StoreContext);

  const onSubmit: SubmitHandler<Inputs> = useCallback(async (data) => {
    setTesting(true);
    setAuthError(null);
    setMessage(null);

    try {
      saveConfig(data);

      const syncer = syncerFromConfig(store);
      if (!await syncer.isAuthenticated()) {
        setAuthError("Failed to authenticate to S3");
        return;
      }

      setMessage("Successfully connected");
      onLoggedIn(syncer);
    } catch (e) {
      setMessage(`Failed to validate: ${e}`);
    } finally {
      setTesting(false);
    }
  }, [onLoggedIn, store, setAuthError]);


  return <Stack mx={2}>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h6" mb={1}>S3 Syncer Setup</Typography>
      <Stack spacing={2}>
        <TextField label="Access Key ID" {...register("s3AccessKeyID", { required: true })} error={!!errors.s3AccessKeyID} helperText={errors.s3AccessKeyID?.message} />
        <TextField type="password" label="Secret Access Key" {...register("s3SecretAccessKey", { required: true })} error={!!errors.s3SecretAccessKey} helperText={errors.s3SecretAccessKey?.message} />
        <TextField label="S3 Endpoint" {...register("s3Endpoint", { required: false })} error={!!errors.s3Endpoint} helperText={errors.s3Endpoint?.message} />
        <TextField label="S3 Region" {...register("s3Region", { required: false })} error={!!errors.s3Region} helperText={errors.s3Region?.message} />
        <TextField label="Bucket Name" {...register("s3Bucket", { required: true })} error={!!errors.s3Bucket} helperText={errors.s3Bucket?.message} />
        {testing ? <CircularProgress /> : <Button type="submit">Save and Test</Button>}
        {authError && <Typography color="error.main">{authError}</Typography>}
        {message && <Typography>{message}</Typography>}
      </Stack>
    </form>
  </Stack>
}
