import yaml from "yaml";
import { Store, generateID } from "./indexeddb";
import { Card } from "./types";


export async function importCards(f: Blob, store: Store): Promise<[valid: number, errs: Error[]]> {
  const errs: Error[] = [];
  let valid: Card[] = [];
  const cardDocs = yaml.parseAllDocuments(await f.text());
  for (const doc of cardDocs) {
    if (doc.errors.length > 0) {
      errs.push(...doc.errors);
      continue;
    }
    if (!doc.contents) {
      continue;
    }
    const card: Card | unknown = doc.contents.toJSON();
    if (validateAndFixupCard(card)) {
      valid.push(card);
    } else {
      errs.push(new Error(`card is not valid: ${JSON.stringify(card)}`));
    }
  }

  await store.addOrUpdateCards(...valid);

  return [valid.length, errs];
}

function validateAndFixupCard(card: any): card is Card {
  if ('front' in card && 'back' in card && 'topic' in card && 'deck' in card) {
    if (typeof card.front !== "string" ||
      typeof card.back !== "string" ||
      typeof card.topic !== "string" ||
      typeof card.deck !== "string") {
      return false;
    }
    if ('id' in card) {
      if (typeof card.id !== "string") {
        return false;
      }
      // Set id if it is blank string.
      if (!card.id) {
        card.id = generateID();
      }
    } else {
      card['id'] = generateID();
    }

    if ('createdOn' in card) {
      if (typeof card.createdOn !== "string") {
        return false;
      }
      try {
        card.createdOn = new Date(Date.parse(card.createdOn));
      } catch (e) {
        return false;
      }
    } else {
      card['createdOn'] = new Date();
    }
    card['lastModified'] = new Date();
    return true;
  }
  return false
}

export async function exportCards(cards: Card[]): Promise<Blob> {
  const yamls = cards.map(c => new yaml.Document(c, (_, v) => {
    if (v instanceof Date) {
      return v.toISOString();
    }
    return v;
  })).map(d => d.toString({
    directives: true,
  }));

  const enc = new TextEncoder();
  return new Blob(yamls.map(y => enc.encode(y)), { type: "application/yaml" });
}
